import api from "./api";
import config from "./config";

const usersList = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.get(config.usersList);
};

const usersAdd = (token, body) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return api.post(config.usersList, body);
};

const userInner = (token, userId) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    return api.get(`${config.usersList}/${userId}`);
  };

  const usersEdit = (token, userId, body) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers['Content-Type'] = 'application/json'
    return api.patch(`${config.usersList}/${userId}`, body);
  };

export default {
  usersList,
  usersAdd,
  userInner,
  usersEdit
};
