import api from "./api";
import config from "./config";

const categories = (credentials) => {
  return api.get(config.paynetCategories, credentials);
};

const provider = (category) => {
  return api.get(config.paynetProvider + category);
};

const service = (provider) => {
  return api.get(config.paynetService + provider);
};

const adminCategoriesList = () => {
    return api.get(config.adminCategoriesList);
};

const adminServiceList = () => {
    return api.get(config.adminServiceList);
};

const serviceAdd = (credentials) => {
    return api.post(config.serviceAdd, credentials)
}

const categoryAdd = (credentials) => {
    return api.post(config.categoryAdd, credentials)
}

const categoryEdit = (credentials, id) => {
    return api.post(`${config.categoryEdit}/${id}`, credentials)
}

const serviceEdit = (credentials, id) => {
    return api.post(`${config.serviceEdit}/${id}`, credentials)
}

export default {
  categories,
  provider,
  service,
  adminCategoriesList,
  adminServiceList,
  serviceAdd,
  categoryAdd,
  categoryEdit,
  serviceEdit
};
