import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [    
    {
      path: '/',
      name: 'home',
      component: () => import('../pages/g-home.vue')
    },
    
    {
      path: '/users',
      name: 'users',
      component: () => import('../pages/g-users.vue')
    },
    
    {
      path: '/users/:id',
      name: 'user-inner',
      component: () => import('../pages/g-user-inner.vue')
    },
    
    {
      path: '/user-add',
      name: 'user-add',
      component: () => import('../pages/g-user-add.vue')
    },
    
    {
      path: '/clients',
      name: 'clients',
      component: () => import('../pages/g-clients.vue')
    }, 
    
    {
      path: '/clients/:id',
      name: 'client-inner',
      component: () => import('../pages/g-client-inner.vue')
    }, 
    
    {
      path: '/clients/:id/card/:card',
      name: 'client-card',
      component: () => import('@/components/clients/g-client-card-info.vue')
    },
    
    {
      path: '/products',
      name: 'products',
      component: () => import('../pages/g-products.vue')
    }, 
    
    {
      path: '/products/:id',
      name: 'product-inner',
      component: () => import('../pages/g-product-inner.vue')
    }, 
    
    {
      path: '/category-service',
      name: 'category-service',
      component: () => import('../pages/g-category-service.vue')
    },
    
    {
      path: '/categories-add',
      name: 'categories-add',
      component: () => import('../pages/g-categories-add.vue')
    }, 
    
    {
      path: '/services-add',
      name: 'services-add',
      component: () => import('../pages/g-services-add.vue')
    }, 
    
    {
      path: '/services-inner/:id',
      name: 'services-inner',
      component: () => import('../pages/g-services-inner.vue')
    }, 
    
    {
      path: '/category-inner/:id',
      name: 'category-inner',
      component: () => import('../pages/g-category-inner.vue')
    }, 
    
    {
      path: '/news',
      name: 'news',
      component: () => import('../pages/g-news.vue')
    }, 
    
    {
      path: '/news-add',
      name: 'news-add',
      component: () => import('../pages/g-news-add.vue')
    }, 
    
    {
      path: '/news-inner/:id',
      name: 'news-inner',
      component: () => import('../pages/g-news-inner.vue')
    }, 
    
    {
      path: '/news-categories-add',
      name: 'news-categories-add',
      component: () => import('../pages/g-news-categories-add.vue')
    }, 
    
    {
      path: '/news-categories-inner/:id',
      name: 'news-categories-inner',
      component: () => import('../pages/g-news-categories-inner.vue')
    }, 
    
    {
      path: '/operators',
      name: 'operators',
      component: () => import('../pages/g-operators.vue')
    }, 
    
    {
      path: '/operators-add',
      name: 'operators-add',
      component: () => import('../pages/g-operators-add.vue')
    }, 
    
    {
      path: '/operators-inner/:id',
      name: 'operators-inner',
      component: () => import('../pages/g-operators-inner.vue')
    }, 
    
    {
      path: '/logs',
      name: 'logs',
      component: () => import('../pages/g-logs.vue')
    }, 
    
    {
      path: '/cards-theme',
      name: 'cards-theme',
      component: () => import('../pages/g-cards-theme.vue')
    }, 
    
    {
      path: '/cards-theme-inner/:id',
      name: 'cards-theme-inner',
      component: () => import('../pages/g-cards-theme-inner.vue')
    }, 
    
    {
      path: '/card-theme-add',
      name: 'card-theme-add',
      component: () => import('../pages/g-card-theme-add.vue')
    }, 
    
    {
      path: '/stories',
      name: 'stories',
      component: () => import('../pages/g-stories.vue')
    }, 
    
    {
      path: '/stories/:id',
      name: 'stories-inner',
      component: () => import('../pages/g-stories-inner.vue')
    }, 
    
    {
      path: '/stories-add',
      name: 'stories-add',
      component: () => import('../pages/g-stories-add.vue')
    }, 
  ]
})

export default router
