<template>
  <g-auth v-if="!TOKEN"></g-auth>
  <g-wrapper v-else></g-wrapper>
</template>

<script>
import gAuth from "@/components/g-auth.vue";
import gWrapper from "@/components/wrapper.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      login: true,
    };
  },
  components: {
    gAuth,
    gWrapper,
  },
  computed: {
    ...mapGetters(["TOKEN"]),
  },
  methods: {
    ...mapActions(["GET_TOKEN"]),
  },
  beforeCreate() {
    if (!localStorage.getItem("token-admin")) {
      if (window.location.pathname != "/") {
        window.location.pathname = "/";
      }
    }
  },
  created() {
    this.GET_TOKEN().then(()=> {
      document.body.classList.add("loaded")
    });
  },
  mounted() {}
};
</script>

<style>
  body {
    opacity: 0;
  }

  body.loaded {
    opacity: 1;
  }
</style>
