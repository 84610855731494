import axios from "axios";
import { createStore } from "vuex";
import auth from "@/api/auth";
import users from "@/api/users";
import paynet from "@/api/paynet";

const store = createStore({
  state: {
    token: "",
    activePage: "",
    usersList: [],
    clients: [],
    paynetCategories: [],
    adminCategoriesList: [],
    loading: false,
  },
  mutations: {
    SET_USERSLIST: (state, usersList) => {
      state.usersList = usersList;
    },
    SET_CLIENTS: (state, clients) => {
      state.clients = clients;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_PAYNETCATEGORIES: (state, paynetCategories) => {
      state.paynetCategories = paynetCategories;
    },
    SET_ADMINCATEGORIESLIST: (state, adminCategoriesList) => {
      state.adminCategoriesList = adminCategoriesList;
    },
  },
  actions: {
    async GET_USERSLIST({ commit }) {
      try {
        const usersList = await users.usersList(localStorage.getItem("token-admin"))
        commit("SET_USERSLIST", usersList);
        return usersList;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async GET_CLIENTS({ commit }) {
      try {
        const clients = await axios("http://localhost:3000/clients", {
          method: "GET",
        });
        commit("SET_CLIENTS", clients.data);
        return clients;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async GET_TOKEN({ commit }) {
      try {
        if (localStorage.getItem("token-admin")) {
          const token = await auth.authMe(localStorage.getItem("token-admin"));
          commit("SET_TOKEN", localStorage.getItem("token-admin"));
          return token;
        } else {
          return "";
        }
      } catch (error) {
        return "";
      }
    },
    async GET_PAYNETCATEGORIES({ commit }) {
      try {
        const paynetCategories = await paynet.categories();
        commit("SET_PAYNETCATEGORIES", paynetCategories.data.data);
        return paynetCategories.data.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async GET_ADMINCATEGORIESLIST({ commit }) {
      try {
        const adminCategoriesList = await paynet.adminCategoriesList();
        commit("SET_ADMINCATEGORIESLIST", adminCategoriesList.data.data);
        return adminCategoriesList.data.data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
  },
  getters: {
    USERSLIST(state) {
      return state.usersList;
    },
    CLIENTS(state) {
      return state.clients;
    },
    TOKEN(state) {
      return state.token;
    },
    PAYNETCATEGORIES(state) {
      return state.paynetCategories;
    },
    ADMINCATEGORIESLIST(state) {
      return state.adminCategoriesList;
    },
  },
});

export default store;
