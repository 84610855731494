<template>
  <div class="wrapper">
    <main class="main main--flex">
      <aside class="main-sidebar">
        <div class="main-slider">
          <ul class="main-slider-list">
            <li class="main-slider-item">
              <div class="main-slider-image">
                <img
                  src="@/assets/img/slide-01.png"
                  width="960"
                  alt="Оплата без комиссий"
                />
              </div>
              <div class="main-slider-content">
                <h3 class="main-slider-title title title--s">
                  Оплата без комиссий
                </h3>
                <p>Связь, интернет и многое другое...</p>
              </div>
            </li>
            <li class="main-slider-item">
              <div class="main-slider-image">
                <img
                  src="@/assets/img/slide-02.png"
                  width="960"
                  alt="Все финансы под рукой"
                />
              </div>
              <div class="main-slider-content">
                <h3 class="main-slider-title title title--s">
                  Все финансы под рукой
                </h3>
                <p>Информация по счетам, картам и кредитам без визита в банк</p>
              </div>
            </li>
            <li class="main-slider-item">
              <div class="main-slider-image">
                <img
                  src="@/assets/img/slide-03.png"
                  width="960"
                  alt="Уже получили логин?"
                />
              </div>
              <div class="main-slider-content">
                <h3 class="main-slider-title title title--s">
                  Уже получили логин?
                </h3>
                <p>
                  Активируйте приложение и совершайте онлайн платежи и переводы
                </p>
              </div>
            </li>
          </ul>
          <div class="main-slider-pagination"></div>
        </div>
      </aside>
      <section class="main-container">
        <header class="main-header">
          <a class="main-header-logo logo" href="./">
            <svg width="195" height="26" aria-label="Логотип Garant Bank">
              <use xlink:href="#icon-logo"></use>
            </svg>
          </a>
          <div class="main-header-col">
            <details class="dropdown lang">
              <summary
                class="dropdown-opener lang-opener"
                aria-label="Открыть языковое меню"
              >
                Ру
              </summary>
              <ul class="dropdown-list lang-list" aria-label="Языковое меню">
                <li class="dropdown-item lang-item">
                  <a class="dropdown-link lang-link" href="./">O`z</a>
                </li>
                <li class="dropdown-item lang-item">
                  <a class="dropdown-link lang-link" href="./">En</a>
                </li>
              </ul>
            </details>
          </div>
        </header>
        <div class="main-content">
          <h1 class="main-title title title--m">Авторизация</h1>
          <form class="main-form form form--flex" @submit="sendForm">
            <label class="form-label">
              <span class="form-name">Номер телефона</span>
              <input
                class="form-item__input"
                type="tel"
                v-imask="phoneMask"
                name="phone"
                placeholder="Введите номер телефона"
                @accept="onAcceptPhoneNumber"
              />
            </label>
            <label class="form-label">
              <span class="form-name">Пароль</span>
              <input
                class="form-item__input"
                :type="passwordView ? 'text' : 'password'"
                name="password"
                v-model="password"
                placeholder="Введите пароль"
              />
            </label>
            <div class="form-footer">
              <button class="btn btn--red" type="submit">Войти</button>
            </div>
          </form>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import Swiper, { Autoplay, Pagination } from "swiper";
import { IMaskDirective } from "vue-imask";
import { useVuelidate } from "@vuelidate/core";
import auth from "@/api/auth";

export default {
  name: "g-auth",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      formError: "",
      passwordView: false,
      password: "",
      phone: "",
      phoneMask: {
        mask: "+998 (00) 000 00 00",
        placeholderChar: " ",
        lazy: true,
      },
    };
  },
  validations() {
    return {
      password: {
        minLength(value) {
          return value.length > 5;
        },
      },
      phone: {
        numbersLength(value) {
          return value.length == 9;
        },
      },
    };
  },
  components: {},
  methods: {
    onAcceptPhoneNumber(e) {
      const maskRef = e.detail;
      this.phone = `998${maskRef.unmaskedValue}`;
    },
    setPhone(value) {
      this.phone = value;
      this.v$.phone.$touch();
    },
    sendForm(event) {
      let vm = this;
      event.preventDefault();
      this.formSending = true;
      auth
        .login({
          phone: this.phone,
          password: this.password,
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("token-admin", response.data.accessToken);
            window.location.pathname = "/";
          } else {
            vm.formSending = false;
            console.log(response);
          }
        })
        .catch(function (error) {
          vm.formSending = false;
          vm.formError = error;
        });
    },
  },
  directives: {
    imask: IMaskDirective,
  },
  mounted() {
    new Swiper(`.main-slider`, {
      modules: [Pagination, Autoplay],
      pagination: {
        el: `.main-slider-pagination`,
        type: `bullets`,
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      wrapperClass: `main-slider-list`,
      slideClass: `main-slider-item`,
      slideActiveClass: `active`,
      slidesPerView: 1,
      speed: 800,
      loop: true,
    });
  },
};
</script>
