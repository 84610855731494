import api from './api'
import config from './config'

const login = (credentials) => {
  return api.post(config.login, credentials)
}

const authMe = (credentials) => {
  api.defaults.headers.Authorization = `Bearer ${credentials}`;
  return api.get(config.authMe)
}

export default {
  login,
  authMe
}
