<template>
  <div class="wrapper wrapper--row">
    <!-- menu -->
    <nav class="menu">
      <div class="menu-inner">
        <div class="menu-header">
          <router-link to="/" class="menu-logo logo" aria-label="На главную">
            <svg width="195" height="26" aria-label="Логотип Garant Bank">
              <use xlink:href="#icon-logo"></use>
            </svg>
          </router-link>
        </div>
        <ul class="menu-list">
          <li class="menu-item">
            <router-link
              to="/"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'home' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-home-2"></use>
                </svg>
              </span>
              <span class="menu-link-text">Главная</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/users"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'users' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-users"></use>
                </svg>
              </span>
              <span class="menu-link-text">Пользователи</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/clients"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'clients' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-clients"></use>
                </svg>
              </span>
              <span class="menu-link-text">Клиенты</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/products"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'products' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-case"></use>
                </svg>
              </span>
              <span class="menu-link-text">Продукты</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/cards-theme"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'cards-theme' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-credit-card"></use>
                </svg>
              </span>
              <span class="menu-link-text">Темы карт</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/category-service"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'category-service' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-processor"></use>
                </svg>
              </span>
              <span class="menu-link-text">Категории и сервисы</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/news"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'news' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-news"></use>
                </svg>
              </span>
              <span class="menu-link-text">Новости</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/stories"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'stories' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-monitor"></use>
                </svg>
              </span>
              <span class="menu-link-text">Истории</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/operators"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'operators' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-operators"></use>
                </svg>
              </span>
              <span class="menu-link-text">Коды операторов</span>
            </router-link>
          </li>
          <li class="menu-item">
            <router-link
              to="/logs"
              class="menu-link"
              :class="{ active: $store.state.activePage == 'logs' }"
            >
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-check-list"></use>
                </svg>
              </span>
              <span class="menu-link-text">Логи</span>
            </router-link>
          </li>
          <li class="menu-item menu-item--mt-auto">
            <button class="menu-link" @click="exid">
              <span class="menu-link-icon">
                <svg width="50" height="50">
                  <use xlink:href="#icon-logout"></use>
                </svg>
              </span>
              <span class="menu-link-text">Выход</span>
            </button>
          </li>
        </ul>
      </div>
    </nav>

    <!-- main -->
    <main class="main" :class="{'loading': $store.state.loading}">
      <!-- header -->
      <header class="header">
        <div class="header-row">
          <div class="header-col">
            <b class="header-title title title--xs">Админ-панель</b>
          </div>
          <div class="header-col">
            <details class="dropdown user">
              <summary class="dropdown-opener user-opener">
                <span class="user-opener-image">
                  <img src="@/assets/img/user-photo.png" width="40" alt="" />
                </span>
                <span class="user-opener-text">Иван Иванов</span>
              </summary>
              <ul class="dropdown-list user-list">
                <li class="dropdown-item user-item">
                  <a class="dropdown-link" href="./">Мой профиль</a>
                </li>
                <li class="dropdown-item user-item">
                  <button type="button" class="dropdown-link" @click="exid" >Выйти</button>
                </li>
              </ul>
            </details>
            <details class="dropdown lang">
              <summary
                class="dropdown-opener lang-opener"
                aria-label="Открыть языковое меню"
              >
                Ру
              </summary>
              <ul class="dropdown-list lang-list" aria-label="Языковое меню">
                <li class="dropdown-item lang-item">
                  <a class="dropdown-link lang-link" href="./">O`z</a>
                </li>
                <li class="dropdown-item lang-item">
                  <a class="dropdown-link lang-link" href="./">En</a>
                </li>
              </ul>
            </details>
          </div>
        </div>
      </header>

      <div class="main-container container">
        <RouterView />
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "g-wrapper",
  data() {
    return {};
  },
  methods: {
    exid(){
      localStorage.removeItem("token-admin");
      window.location.pathname = "/";
    }
  },
  components: {},
};
</script>

<style lang="css" scoped>
.main {
  display: flex;
  flex-direction: column;
}
</style>
