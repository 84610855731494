import "./assets/css/main.min.css";
import 'air-datepicker/air-datepicker.css';

import { createApp } from "vue";
import App from "./App.vue";
import store from "./vuex/store";
import router from "./router/router";

const app = createApp(App);
app.use(router).use(store).mount("#app");

if (document.querySelector("details")) {
  window.addEventListener(`click`, (e) => {
    if (document.querySelector(`details[open]`)) {
      let target = e.target.closest(`details[open]`);

      if (!target && e.target.tagName !== `BUTTON`) {
        document.querySelectorAll(`details[open]`).forEach((item) => {
          item.removeAttribute(`open`);
        });
      }
    }
  });
}
